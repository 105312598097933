<template>
  <div class="order_matter">
    <div class="matter_body">
      <div class="box_item">
        <div class="item_title">请选择区域</div>
        <van-field
          class="item_field"
          v-model="form.deptName"
          placeholder="请选择"
          readonly
          :error='openError&&!form.deptName'
          is-link
          @click="openPop('deptPopShow')"
        />
      </div>
      <div class="box_item">
        <div class="item_title">请选择事项</div>
        <van-field
          class="item_field"
          v-model="form.matterName"
          placeholder="请选择"
          readonly
          :error='openError&&!form.matterName'
          is-link
          @click="openPop('matterPopShow')"
          :rules="[{ required: true, message: '请选择事项' }]"
        />
      </div>
      <div class="box_item">
        <div class="item_title">请选择预约时间</div>
        <div class="item_row">
          <van-field
            class="item_field"
            v-model="form.date"
            placeholder="请选择"
            readonly
            :error='openError&&!form.date'
            is-link
            @click="openPop('datePopShow')"
            :rules="[{ required: true, message: '请选择预约日期' }]"
          />
          <van-field
            class="item_field"
            v-model="form.time"
            placeholder="请选择"
            readonly
            :error='openError&&!form.time'
            is-link
            @click="openPop('timePopShow')"
            :rules="[{ required: true, message: '请选择预约时间' }]"
          />
        </div>
      </div>
    </div>
    <div class="matter_bottom" @click="submit">提交</div>
    <!-- 业务类型 -->
    <van-action-sheet
      v-model="showFlagObj.deptPopShow"
      :actions="optionObj.deptOption"
      @select="onSelect($event,'deptPopShow')"
    />
    <!-- 服务事项 -->
    <van-action-sheet
        v-model="showFlagObj.matterPopShow"
        :actions="optionObj.matterOption"
        @select="onSelect($event,'matterPopShow')"
    />
    <!-- 预约日期 -->
    <van-action-sheet
      v-model="showFlagObj.datePopShow"
      :actions="optionObj.dateOption"
      @select="onSelect($event,'datePopShow')"
    />
    <!-- 预约时间段 -->
    <van-action-sheet
      v-model="showFlagObj.timePopShow"
      :actions="optionObj.timeOption"
      @select="onSelect($event,'timePopShow')"
    />
  </div>
</template>

<script>
import {
  Field,
  ActionSheet,
  Toast
} from "vant";
import { mapState } from "vuex";
import {
  getSevenWorkDaysAfterToday,
  appointmentdept,
  appointmentmatter,
  getCanAppointmentTime,
  appointmentmatterrecord
} from '@/api/order'
export default {
  name: "orderMatter",
  components: {
    [Field.name]: Field,
    [ActionSheet.name]:ActionSheet
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      openError:false,
      form:{
        deptName:'',
        deptId:'',
        matterName:'',
        matterId:'',
        date:'',
        time:'',
      },
      optionObj:{
        deptOption:[],
        matterOption:[],
        dateOption:[],
        timeOption:[]
      },
      showFlagObj:{
        deptPopShow:false,
        matterPopShow:false,
        datePopShow:false,
        timePopShow:false
      }
    };
  },
  mounted() {
    appointmentdept().then(res=>{
      if(res.success){
        this.optionObj.deptOption=res.data.map(item=>{
          return {
            name:item.deptName,
            value:item.id
          }
        })
      }
    })
    getSevenWorkDaysAfterToday().then(res=>{
      if(res.success){
        this.optionObj.dateOption=res.data.map(item=>{
          return {
            name:item.date,
            value:item.date
          }
        })
      }
    });
  },
  methods: {
    //打开弹出窗口
    openPop(key){
      if (key==='deptPopShow'&&this.optionObj.deptOption.length===0){
        return Toast('暂无区域选项')
      }else if(key==='matterPopShow'&&!this.form.deptId){
        return Toast('请先选择区域');
      }else if(key==='matterPopShow'&&this.optionObj.matterOption.length===0){
        return Toast('暂无事项选项');
      }else if(key==='datePopShow'&&!this.form.matterId){
        return Toast('请先选择事项');
      }else if(key==='datePopShow'&&this.optionObj.dateOption.length===0){
        return Toast('暂无日期选项');
      }else if(key==='timePopShow'&&!this.form.date){
        return Toast('请先选择日期');
      }else if(key==='timePopShow'&&this.optionObj.timeOption.length===0){
        return Toast('暂无时间选项');
      }
      this.showFlagObj[key]=true;
    },
    //选择
    async onSelect(e,type){
      if(type==='deptPopShow'){
        this.form={
          deptName:e.name,
          deptId:e.value,
          matterName:'',
          matterId:'',
          date:'',
          time:'',
        };
        appointmentmatter({deptId:e.value}).then(res=>{
          if(res.success){
            this.optionObj.matterOption=res.data.map(item=>{
              return {
                name:item.matterName,
                value:item.matterCode
              }
            })
          }
        })
      }else if(type==='matterPopShow'){
        this.form.matterName = e.name; 
        this.form.matterId = e.value; 
        this.form.date = ''; 
        this.form.time = ''; 
      }else if(type==='datePopShow'){
        this.form.date = e.value; 
        this.form.time = ''; 
        getCanAppointmentTime({date:e.name}).then(res=>{
          if(res.success){
            const isToDay = new Date().getDate() ===new Date(e.value).getDate();
            const nowTime = new Date().toTimeString().slice(0,5);
            this.optionObj.timeOption=res.data.map(item=>{
              return {
                name:`${item.startTime}-${item.endTime}剩余数量${item.personNum}`,
                value:`${item.startTime}-${item.endTime}`,
                disabled:isToDay?nowTime>item.endTime||item.personNum===0:item.personNum===0
              }
            })
          }
        })
      }else if(type==='timePopShow'){
        this.form.time = e.value; 
      }
      this.showFlagObj[type]=false;
    },
    // 预约提交
    submit(){
      if(!this.form.time){
        return this.openError=true;
      }
      // appointmentmatterrecord
      const params = {
        appointmentDate:this.form.date,
        startTime:this.form.time.split('-')[0],
        endTime:this.form.time.split('-')[1],
        matterId:this.form.matterId,
        matterName:this.form.matterName,
        phone:this.userInfo.phone,
      }
      console.log(this.form);
      appointmentmatterrecord(params).then(res=>{
        console.log(res);
        if(res.success){
          this.$router.push({path:"/orderSuccess",query:{id:res.data.id}});
        }else{
          Toast(res.msg)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.order_matter{
  height: 100vh;
  overflow: hidden;
  padding: 36px 12px 24px;
  background-image: linear-gradient(to bottom,
    #3AA0FF 0%,
    #ffffff 70%,
    #ffffff 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .matter_body{
    .box_item{
      padding: 16px 12px;
      border-radius: 4px;
      background-color: #fff;
      +.box_item{
        margin-top: 12px;
      }
      .item_title{
        margin-bottom: 8px;
        color: #333333;
        font-size: 15px;
        line-height: 16px;
        font-weight: 500;
      }
      .item_field{
        padding: 4px 12px;
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        background-color: #fff;
        color: #0c0f18;
        font-size: 14px;
        line-height: 24px;
      }
      .item_row{
        display: flex;
        .item_field:first-of-type {
            margin-right: 12px;
        }
      }
    }
  }

  .matter_bottom{
    border-radius: 5px;
    background-color: #1677FF;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
  }
}
</style>